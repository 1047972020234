

// Custom Styles

.navLogo {
  max-height: 2.75rem !important;
}

.cover-pic {
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.profile-pic-settings {
  height: 200px;
  width: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // border-radius: 100%;

}

.cover-pic-settings {
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}

.profile-pic {
  height: 150px;
  width: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
  // border-width: 5px;
  // border: 5px solid white;
  z-index: 1000000000;
  margin-top: -110px;
  margin-bottom: 20px;
}

.light-shadow {
  box-shadow: rgb(204, 204, 204) 2px 2px 7px;
}

.pointer {
  cursor: pointer;
}

.box-border {
  border: .5px solid rgba(92,92,92,.267)
}

.hov-trans-bg:hover {
  background-color: transparent !important;
}
 

.content-text {
  font-size: 24px;
}

.fullBox {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
  color: #4a4a4a;
  display: block;
}

.masthead {
  background: no-repeat 50%;
  background-color: #868e96;
  background-attachment: scroll;
  position: relative;
  background-size: cover;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

//Bulma sass Changes

@import url("https://use.typekit.net/zgz6cbf.css?ver=5.3.2");

$branding-dark:  #333132;

$dark: $branding-dark;

$branding-red: #e2574c;

$family-primary: 'jaf-domus', sans-serif;

$primary: $branding-red;

$body-background-color: #f6f6f6;

$box-shadow: 0;

$tabs-link-active-color: $branding-red;
$tabs-link-active-border-bottom-color: $branding-red;
$modal-content-width: 800px;


@import '../node_modules/bulma/bulma.sass';


// Quill CSS overrides

.ql-container {
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ql-toolbar {
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-family: $family-primary;
}

.ql-editor {
  min-height: 400px;
  font-size: 18px;
  font-family: $family-primary;
}